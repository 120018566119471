import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { v4 } from "uuid"
import { GatsbyImage } from "gatsby-plugin-image"

import RightLineArrowIcon from "../Icons/RightLineArrowIcon"
import PrimaryLinkButton from "../Button/PrimaryLinkButton"
import BreakpointUp from "../Media/BreakpointUp"

const CardArticle = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  margin: 0 -15px;
  ${BreakpointUp.lg`				
        flex-direction: row-reverse;	
        margin:0 -30px;
    `}
  &:before {
    content: "";
    background: #fff;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    ${BreakpointUp.lg`		
            width: 70%;
        `}
  }
`

const CardArticleFigure = styled.div`
  position: relative;
  width: 100%;
  padding: 25px 15px;
  ${BreakpointUp.lg`		
        padding:50px 15px;
        flex: 0 0 50%;
        max-width: 50%;
    `}
  figure {
    position: relative;
    margin: 0;
  }
`
const CardArticleBody = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 15px;
  ${BreakpointUp.lg`		
        padding:30px 15px 30px 30px;
        flex: 0 0 50%;
        max-width: 50%;
	`}
`
const CardArticleTitle = styled.div`
  margin: 0 0 10px;
  font-weight: 900;
  font-size: 20px;
  line-height: 30px;
  @media (min-width: 992px) {
    font-size: 24px;
    line-height: 34px;
  }
  a {
    color: #000;
    &:hover,
    &:focus {
      color: #0b619b;
    }
  }
`
const CardArticleSubTitle = styled.span`
  margin: 0 0 10px;
  font-weight: 500;
  font-size: 14px;
  color: #999;

  span:after {
    content: ", ";
  }

  span:last-child {
    &:after {
      content: "";
    }
  }
`
const CardArticleText = styled.div`
  p {
    margin-left: 0px !important;
  }
`
const BtnToolbar = styled.div`
  .btn {
    padding: 0;
    font-size: 14px;
    .icon {
      svg {
        width: 16px;
      }
    }
  }
`
const PostedDate = styled.span`
  background-color: #fc0002;
  color: #fff;
  text-align: center;
  position: absolute;
  bottom: 0;
  right: 0;
  min-width: 70px;
  min-height: 60px;
  padding: 8px 5px;
  strong,
  span {
    display: block;
    font-weight: 900;
  }
  strong {
    font-size: 32px;
    line-height: 24px;
  }
  span {
    font-size: 16px;
    line-height: 20px;
  }
`
const ArticleCard = ({ data }) => {
  const postDate = data.postDate.slice(0, 2)
  const postMonth = data.postDate.slice(3)
  return (
    <CardArticle className="card">
      <CardArticleFigure className="card-img">
        <figure>
          <GatsbyImage
            image={data.image.gatsbyImageData}
            alt={data.image.title}
          />
          <PostedDate>
            <strong>{postDate}</strong>
            <span>{postMonth}</span>
          </PostedDate>
        </figure>
      </CardArticleFigure>
      <CardArticleBody className="card-body">
        <CardArticleSubTitle className="card-subtitle">
          {data.blogCategories ? (
            data.blogCategories.map(item => <span key={v4()}>{item.name}</span>)
          ) : (
            <></>
          )}
        </CardArticleSubTitle>
        <CardArticleTitle className="card-title">
          <Link to={data.url}>{data.title}</Link>
        </CardArticleTitle>
        <CardArticleText className="card-desc">
          {data.description && (
            <div
              dangerouslySetInnerHTML={{
                __html: data.description.childMarkdownRemark.html,
              }}
            />
          )}
        </CardArticleText>
        <BtnToolbar>
          <Link to={data.url}>
            <PrimaryLinkButton
              icon={<RightLineArrowIcon />}
              text="Read Full Article"
            />
          </Link>
        </BtnToolbar>
      </CardArticleBody>
    </CardArticle>
  )
}

export default ArticleCard
