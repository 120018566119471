import React, { Component } from 'react'
import styled from "styled-components"
import Slider from "react-slick"
import { v4 } from 'uuid'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { ArrowLeftIcon, ArrowRightIcon } from "../../components/Icons"
import ArticleCard from "../../components/ArticleCard"
import BreakpointUp from "../../components/Media/BreakpointUp"

const SlickSlider = styled.div` 
  padding: 0;
  max-width: 1920px;
  margin: 0 auto;
  width: 100%;
`
const SlickItem = styled.div`
  display:block !important;
  outline:none;
  max-width:100%;
  padding: 0 5px;
  ${BreakpointUp.lg`
    padding: 0 15px;    
  `}
  .card{
    flex-direction:column;
    margin:0;
    &:before{
      width:100%;
      top: auto;
      border: 1px solid #D3D3D3;
      height: 88%;
    }
    .card-title{
      > a{
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
    .card-img{
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
      padding:15px;
      ${BreakpointUp.lg`
        padding:15px 30px;
      `}
    }       
    .card-body{     
      min-height: 294px;
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
      padding:15px;      
	    ${BreakpointUp.lg`		
        padding:15px 30px;
      `}      
    }
    .card-desc{
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      p{
        + p{
          display:none;
        }
      } 
    }

  }
`
const SlickArrowTrack = styled.div`
  display:none;
  position: absolute;
  top: calc(50% - 45px);
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  right: 0;
  min-height: 30px;
  width: 100%;
  &:hover {
    cursor: pointer; 
  }
`
const LeftArrow = styled.div`
  position: absolute;  
  width: 36px;
  height: 36px;
  border-radius:50%;
  background-color:#fff;
  left:-18px;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;
  svg{
    fill:#FC0002;
  }
  &:hover {
    background-color:#0B619B;
    svg{
      fill:#fff;
    }  
  }
`
const RightArrow = styled.div`
  position: absolute;  
  width: 36px;
  height: 36px;
  border-radius:50%;
  background-color:#fff;
  right:-18px;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;
  svg{
    fill:#FC0002;
  }
  &:hover {
    background-color:#0B619B;
    svg{
      fill:#fff;
    }  
  }
`

function CircleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "flex"}}
      onClick={onClick}
      aria-hidden="true"
    ><ArrowRightIcon/></div>
  );
}

function CirclePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "flex"}}
      onClick={onClick}
      aria-hidden="true"
    ><ArrowLeftIcon/></div>
  );
}

export default class SimilarArticlesCarousel extends Component {
  constructor(props) {
    super(props)
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  render() {
    const { data } = this.props
    const settings = {
      slidesToShow: 3,
      slidesToScroll: 1,
      speed: 500,
      nextArrow: <CircleNextArrow />,
      prevArrow: <CirclePrevArrow />,
      arrow: false,
      centerMode: true,
      centerPadding: '95px',            
      infinite: true,
      focusOnSelect: true,
      responsive: [
        {
          breakpoint: 1366,
          settings: {
            centerPadding: '30px'
          }
        },
        {
          breakpoint: 992,
          settings: {
            centerPadding: '30px'
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            centerPadding: '30px'
          }
        }
      ]  
    };
    return (      
      <SlickSlider>       
        <Slider  {...settings} ref={c => (this.slider = c)}>          
          {
            data.map(item => (
              <SlickItem key={v4()}>
                <ArticleCard data={item.node}  />
              </SlickItem>
            ))
          }
        </Slider>
        <SlickArrowTrack>
          <LeftArrow onClick={this.previous}>
              <ArrowLeftIcon />
          </LeftArrow>
          <RightArrow onClick={this.next}>
            <ArrowRightIcon />
          </RightArrow>
        </SlickArrowTrack>
      </SlickSlider>
    );
  }
}
