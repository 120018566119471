import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { v4 } from "uuid"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import {
  Section,
  SectionTitle,
  BreadCrumb,
  MarkdownContent,
} from "../components/Section"
import CustomBuildingQuote from "../components/CustomBuildingQuote"
import SimilarArticlesCarousel from "../components/SimilarArticlesCarousel"
import BreakpointUp from "../components/Media/BreakpointUp"


const BackgroundImage = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.9);
  }
`

const SectionWhite = styled.div`
  background-color: #fff;
  position: relative;
  max-width: 1120px;
  margin: 0 auto;
  width: 100%;
  top: -20px;
  padding: 30px 10px;
  ${BreakpointUp.md`  
    top:-30px;
    padding:30px;
  `}
  ${BreakpointUp.lg`  
    top:-60px;
    padding:60px 85px;
  `}
`

const TopHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  max-width: 1120px;
  width: 100%;
  margin: 0 auto;
`
const TopHeaderFigure = styled.div`
  position: relative;
  width: 100%;
  ${BreakpointUp.lg`      
    padding:0 10px;
    flex: 0 0 50%;
    max-width: 50%;
  `}
  figure {
    position: relative;
    margin: 0;
  }
`
const TopHeaderBody = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  ${BreakpointUp.lg`      
    padding:0 10px;
    flex: 0 0 50%;
    max-width: 50%;
  `}
`
const TopHeaderTitle = styled.h1`
  display: block;
  margin: 0;
  font-size: 38px;
  line-height: 48px;
  @media (min-width: 768px) {
    font-size: 58px;
    line-height: 68px;
  }
  @media (min-width: 992px) {
    font-size: 62px;
    line-height: 72px;
  }
`
const TopHeaderSubTitle = styled.span`
  display: block;
  margin: 0 0 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #444;
  span:after {
    content: ", ";
  }

  span:last-child {
    &:after {
      content: "";
    }
  }
`
const PostedDate = styled.span`
  display: inline-block;
  background-color: #fc0002;
  color: #fff;
  text-align: center;
  min-width: 70px;
  min-height: 60px;
  padding: 8px 5px;
  margin-bottom: 20px;
  strong,
  span {
    display: block;
    font-weight: 900;
  }
  strong {
    font-size: 32px;
    line-height: 24px;
  }
  span {
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
  }
`

const ArticlesDetail = ({ data, location }) => {
  const allArticles = data.allContentfulArticle.edges
  const articleData = data.contentfulArticle
  const postDate = articleData.postDate.slice(0, 2)
  const postMonth = articleData.postDate.slice(3)
  let similarArticls = []

  allArticles.forEach(function (item) {
    var flag = 0
    articleData?.blogCategories?.forEach(function (category) {
      item.node.blogCategories?.forEach(function (node) {
        if (node.name === category.name) {
          flag = flag + 1
        }
      })
    })
    if (flag !== 0) {
      similarArticls.push(item)
    }
  })
  return (
    <Layout location={location}>
      <Seo title={articleData.metaTitle} description={articleData.metaDescription.metaDescription} />
      <BreadCrumb>
        <div className="container">
          <Link to="/">Home / </Link>
          <Link to="/articles">Articles / </Link>
          <span>{articleData.title}</span>
        </div>
      </BreadCrumb>
      <Section
        pt="215px"
        pb="150px"
        xpt="120px"
        xpb="90px"
        bgColor="#FAFAFA"
        bdrBottom="#707070"
      >
        <BackgroundImage>
          <GatsbyImage
            image={articleData.image.gatsbyImageData}
            alt={articleData.image.title}
          />
        </BackgroundImage>
        <div className="container">
          <TopHeader>
            <TopHeaderBody>
              <PostedDate>
                <strong>{postDate}</strong>
                <span>{postMonth}</span>
              </PostedDate>
              <TopHeaderSubTitle>
                {articleData?.blogCategories?.map(item => (
                  <span key={v4()}>{item.name}</span>
                ))}
              </TopHeaderSubTitle>
              <TopHeaderTitle>{articleData.title}</TopHeaderTitle>
            </TopHeaderBody>
            <TopHeaderFigure>
              <figure>
                <GatsbyImage
                  image={articleData.image.gatsbyImageData}
                  alt={articleData.image.title}
                />
              </figure>
            </TopHeaderFigure>
          </TopHeader>
        </div>
      </Section>
      <Section pt="0" pb="0" xpt="0" xpb="0" bgColor="#fff">
        <div className="container">
          <SectionWhite>
            <MarkdownContent>
              <div
                dangerouslySetInnerHTML={{
                  __html: articleData.content.childMarkdownRemark.html,
                }}
              />
            </MarkdownContent>
          </SectionWhite>
        </div>
      </Section>
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#F2F4F9">
        <div className="container">
          <SectionTitle maxWidth="700px" mb="60px">
            Get Your Custom Building Quote in Just a few Clicks
          </SectionTitle>
          <CustomBuildingQuote states={data.allContentfulState.edges} />
        </div>
      </Section>
      <Section pt="90px" pb="60px" xpt="60px" xpb="30px" bgColor="#fff">
        <div className="container">
          <SectionTitle mb="30px">Explore Similar Articles</SectionTitle>
        </div>
        <SimilarArticlesCarousel data={similarArticls} />
      </Section>
    </Layout>
  )
}

export default ArticlesDetail

export const pageQuery = graphql`
  query ArticlesDetailQuery($id: String!) {
    contentfulArticle(id: { eq: $id }) {
      metaTitle
      metaDescription {
        metaDescription
      }
      title
      image {
        title
        gatsbyImageData
      }
      createdAt(formatString: "DD MMM")
      postDate(formatString: "DD MMM YYYY")
      description {
        childMarkdownRemark {
          html
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
      featuredArticle
      blogCategories {
        name
      }
    }
    allContentfulArticle {
      edges {
        node {
          id
          url
          metaTitle
          metaDescription {
            metaDescription
          }
          title
          image {
            title
            gatsbyImageData
          }
          createdAt(formatString: "DD MMM")
          postDate(formatString: "DD MMM YYYY")
          description {
            childMarkdownRemark {
              html
            }
          }
          featuredArticle
          blogCategories {
            name
          }
        }
      }
    }
    allContentfulState(sort: { order: ASC, fields: name }) {
      edges {
        node {
          name
        }
      }
    }
  }
`
